import { CardProject } from './Steps/CardProject';
import imgGithub from '../../assets/icons/icon-github.png';

import './styles.scss';


export const WebProjects = () => {
    return (
        <>
            <header className='header-web text-violet-50 h-[90vh] w-full '>
                {/* <img src={BannerWP} alt="Banner" className='absolute w-full' /> */}
                <div className='container lg:h-full flex items-center '>
                    <div className='xl:grid xl:grid-cols-2 '>
                        <h2 className='w-auto lg:w-2/3 xl:w-full xl:mx-0 text-3xl smx:text-xl  sm:text-5xl lg:leading-[3.5rem] pb-8 xl:pb-0 pt-24 smx:pt-8 sm:pt-72 lg:pt-0 pr-10 smx:pr-5 lg:pr-0 pl-10 smx:pl-5 lg:pl-0 col-10 self-center tracking-wider'>
                            Confira aqui alguns dos
                            <b> projetos que desenvolvi</b>, 
                            sendo alguns <b>criados por mim </b>
                            e outro <b>desenvolvidos em cursos e trabalho</b>.
                        </h2>
                        <div className='self-center'>
                            <h3 className='px-5 text-center text-lg lg:text-xl'>Confira meu GitHub para ter acesso aos códigos e também a mais projetos.</h3>
                            <div className='flex justify-center'>
                                <a 
                                    className='pl-4 pr-9 mt-3 py-2 inline-flex rounded-md border-2 border-violet-500 animate-pulse hover:text-white hover:bg-violet-500 transition-all' 
                                    href='https://github.com/Lucashylario' target="_blank"
                                >
                                    <img src={imgGithub} className='inline mr-4 w-7' alt='Icone github' />
                                    Acessar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className='container'> 
                <CardProject />
            </div>
        </>
    )
}