import { Container } from 'react-bootstrap';
import './styles.scss';
import aboultPhoto from '../../../assets/photo-aboult.png'

export const AboultComponent = () => {
    return (
        <div className="aboult pt-5 pb-5 my-5 text-white" id='AboultComponent'>
            <Container>
                <div className="mt-16">

                    <div className=" justify-center flex aboult-img">
                        <img className='w-60 mb-4 hover:animate-pulse transition-all' src={aboultPhoto} alt="Foto" />
                    </div>

                    <div className='w-2/4 mx-auto'>
                        <h2 className='w-36 mb-7 mt-2 text-3xl font-bold border-b-2 border-reds-500'>Sobre:</h2>
                        <div className='flex flex-col gap-2'>
                            <p>
                                Formado em desevolvimento web front end pela
                                Imagine School, atualmente estou graduando
                                Análise e Desenvolvimento de Sistemas pela Anhanguera.
                                Tenho conhecimento em edição e produção de vídeos
                                e imagens.
                            </p>
                            <p>
                                Sou apaixonado pelas áreas de tecnológica e audiovisual,
                                além da minha maior paixão que é a música.
                                Sou focado, me dedico ao máximo em tudo que faço, 
                                gosto de trabalhar em ambientes que valorizam
                                os esforços dos colaboradores, que sejam organizados, 
                                ambientes descontraídos e com perspectiva de crescimento 
                                pessoal e profissional.                                 
                            </p>
                            <p>
                                Minha primeira experiência profissional como desenvolvedor
                                foi em uma das principais empresas lançadora de infoprodutos do Brasil, a Kebook.
                                Por lá trabalhei por seis meses até que decidi me desligar da empresa para
                                buscar novos desafios profissionais que me fizessem evoluir ainda mais.
                            </p>
                            <p>
                                Não sou do tipo de pessoa que é acomodada, estou sempre buscando evoluir tanto
                                profissionalmente quanto como pessoa. Meu objetivo profissional 
                                é me tornar um desenvolvedor full stack, sei que tenho um longo caminho ainda
                                para percorrer até atingir essa minha meta, mas dando um passo de cada vez 
                                um dia chegarei lá, e irei persistir até conseguir.
                            </p>
                            <p>
                                Agradeço seu tempo para me conhecer um pouco, caso queira me conhecer 
                                um pouco melhor, sinta-se à vontade para me chamar no privado pelas minhas
                                redes sociais, <a href='https://www.linkedin.com/in/lucas-hylario/' className='font-bold text-violet-200 hover:text-violet-300'>linkedin </a>  
                                ou <a href='https://www.instagram.com/hylario_lucas/' className='font-bold text-violet-200 hover:text-violet-300'>Instagram</a> ou então pode me enviar 
                                uma mensagem por e-mail e assim que possível retornarei seu e-mail como o maior prazer.                            
                            </p>
                            <p>
                                Até breve! 
                            </p>
                            <a href="/#contactForm" className='w-1/3 font-bold text-center mx-auto py-2 rounded-md bg-violet-500 hover:bg-violet-700 hover:text-violet-50 mt-3'>Contato</a>
                        </div>
                    </div>

                </div>
            </Container>
        </div>
    )
}