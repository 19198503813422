import './styles.scss';

export const VideoProjects = () => {
    return (
        <div className='text-white'>
            <h2 className='title mx-auto my-4'>Trabalhos de Videomaker</h2>
            <div className='col-10 mx-auto mb-10 text-container'>
                <p>
                    Minha paixão pelo audiovisual começou em 2016 quando dei início ao meu canal no YouTube, gostei tanto de editar meus vídeos que com o tempo fui me aprimorando nas edições.
                </p>
                <p>
                    Em 2018 comecei a fazer alguns trabalhos como freela de edição, fiz diversos trabalhos, tanto para redes sociais, quanto para empresas. Depois de um tempo comecei a me aventurar em produções de vídeos também, consgui produzir alguns clipes e vídeos para o instagram. 
                </p>
                <p>
                    Apesar de ser limitado em equipamentos, nunca deixei de fazer o meu melhor nos meus trabalhos, e sempre consegui gerar bons resultados. Com isso gostaria de compartilhar dois dos meus pricipais trabalhos, que tive o prazer de fazer toda a produção, e edição de ambos.
                </p>
            </div>

            <div className="section-video-left col-11 col-lg-8 mx-auto row p-4">
                <iframe className='video mx-auto mx-xxl-0 col-12 col-lg-5' src="https://www.youtube.com/embed/38zjgRDwzdU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <div className="content mx-auto mx-xxl-2 col-12 col-lg-4 col-xxl-5 pt-4 ps-4">
                    <h3>Clipe "Pisa no freio"</h3>

                    <p className='pt-3'> 
                        Produzido em 2019, este foi meuu primeiro trabalho produzindo a gravação de um clipe. Alé das gravações, roteiro e edição, também participei da gravação, produção e mixagem da música.
                    </p>
                </div>
            </div>

            <div className="section-video-right col-11 col-lg-8 mx-auto row p-4 my-4 d-flex justify-content-end">
                <div className="content d-none d-xl-block col-lg-4 col-xxl-5 pt-4 pe-4">
                    <h3>Clipe "General (Vybz Kartel)"</h3>

                    <p className='pt-3'> 
                        Produzido em 2021, em parceria com o cantor Ras Jonas, este foi um dos clipes mais desafiadores que já pude produzir, e apesar da limitação de equipamentos e um curto período de tempo para gravar e concluir toda a parte de edição, o resultado saiu exelente. Posso dizer que este foi um dos trabalhos que mais gostei de fazer.
                    </p>
                </div>

                <iframe className='video mx-auto mx-xxl-0 col-12 col-lg-5' src="https://www.youtube.com/embed/3TmKK_MZZeQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />

                <div className="content d-block d-xl-none mx-auto col-12 col-lg-5 pt-4 pe-4">
                    <h3>Clipe "General (Vybz Kartel)"</h3>

                    <p className='pt-3'> 
                        Produzido em 2021, em parceria com o cantor Ras Jonas, este foi um dos clipes mais desafiadores que já pude produzir, e apesar da limitação de equipamentos e um curto período de tempo para gravar e concluir toda a parte de edição, o resultado saiu exelente. Posso dizer que este foi um dos trabalhos que mais gostei de fazer.
                    </p>
                </div>

            </div>            
        </div>
    )
}