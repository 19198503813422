import { BodyComponent } from "../../components/Global/BodyComponent";
import { NavBar } from "../../components/Global/NavBar";
import { Header } from "../../components/Home/Header";
import { ProjectCards } from "../../components/Home/ProjectCards";
import { SkillComponent } from "../../components/Home/SkillComponent";
import { AboultComponent } from "../../components/Home/AboultComponent";
import { Footer } from "../../components/Global/Footer";
import { Contact } from "../../components/Global/Contact";
import { FormContact } from "../../components/Home/FormContact";

export const Home = () => {
    return (
        <BodyComponent>
            <Contact />
            < NavBar />
            < Header />
            <AboultComponent />
            <ProjectCards />
            <SkillComponent />
            <FormContact />
            <Footer />
        </BodyComponent>
    )
}