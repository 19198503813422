import iconInstagram from '../../../assets/icons/icon-instagram.png';
import iconLinkedin from '../../../assets/icons/icon-linkedin.png';
import iconGitHub from '../../../assets/icons/icon-github.png';

export const Contact = () => {
    
    const buttonContact = [
        { image: iconInstagram, title:"Instagram", alt: "Logo do instagram", link: 'https://www.instagram.com/hylario_lucas/'},
        { image: iconLinkedin, title:"Linkedin", alt: "Logo do Linkedin", link: 'https://www.linkedin.com/in/lucas-hylario-a78a2b128/'},
        { image: iconGitHub, title:"GitHub", alt: "Logo do Github", link: 'https://github.com/Lucashylario'},
    ];

    const renderButton = (btn, index) => {
        return (
            <div key={index} className='flex flex-col items-end'>
                <a href={btn.link} target='_blank'>
                    <button className="text-white pl-3 py-3 mb-2 bg-violet-500 hover:bg-violet-700 items-center flex rounded-l-2xl group shadow-xl">
                        <img className="w-8" src={btn.image} alt={btn.alt} />
                        <span 
                            className="
                            max-w-0 
                            overflow-hidden 
                            group-hover:max-w-full 
                            transition-all 
                            duration-100 
                            ease-linear 
                            mx-1"
                            >
                            {btn.title}
                        </span>              
                    </button>
                </a>
            </div>
        )
    }

    return (
        <div  className='fixed z-10 right-0 bottom-3'>
            {buttonContact.map(renderButton)}
        </div>
    )
}