import emailjs from '@emailjs/browser';
import emailIcon from '../../../assets/icons/ilustration-email.png';

export const FormContact = () => {
  
    function sendEmail(e) {
        e.preventDefault();
  
        emailjs.sendForm('emailMessage', 'template_a422t4h', e.target, 'WxYYOwXnUIEWD7txU')
        .then((result) => {
            alert('Mensagem enviada com sucesso! ;)')
        }, (error) => {
            alert(error.message)
        });
        e.target.reset(); // Irá limpar os campos inputs
    };
  
    return (
        <div className='container mb-3' id='contactForm'>
            <form className="font-bold lg:grid grid-cols-2 flex flex-col-reverse lg:flex-col" onSubmit={sendEmail}>
                <div className='flex flex-col gap-4 py-5 justify-center items-center text-violet-1000 bg-violet-50'>
                    <h2 className='text-4xl uppercase underline underline-offset-1'>Contato</h2>
                    
                    <div className='flex flex-col'>
                        <label>Nome</label>
                        <input className='w-80 p-2 text-lg rounded-lg text-violet-1000 font-semibold border-2 border-violet-200 focus:border-2 focus-visible:outline-none focus:border-violet-900' type="text" name="name" placeholder='Digite seu nome'/>
                    </div>

                    <div className='flex flex-col'>
                        <label>Email</label>
                        <input className='w-80 p-2 text-lg rounded-lg text-violet-1000 font-semibold border-2 border-violet-200 focus:border-2 focus-visible:outline-none focus:border-violet-900' type="email" name="email" placeholder='Digite seu e-mail'/>
                    </div>

                    <div className='flex flex-col'>
                        <label>Assunto</label>
                        <input className='w-80 p-2 text-lg rounded-lg text-violet-1000 font-semibold border-2 border-violet-200 focus:border-2 focus-visible:outline-none focus:border-violet-900' type="text" name="subject" placeholder='Assunto do e-mail'/>
                    </div>

                    <div className='flex flex-col'>
                        <label>Mensagem</label>
                        <textarea className='h-32 w-80 rounded-lg p-2 text-violet-1000 font-semibold border-2 border-violet-200 focus:border-2 focus-visible:outline-none focus:border-violet-900' placeholder='Deixe sua mensagem' name="message" />
                    </div>

                    <input 
                        type="submit" 
                        value="Enviar"
                        className='bg-violet-500 mb-2 hover:bg-violet-700 text-white font-bold px-20 py-2 rounded-lg'
                    />
                </div>
                <div className='flex justify-center items-center p-2 lg:p-0 bg-violet-500 flex-col'>
                    <img src={emailIcon} className="w-auto lg:mr-20" alt="Ilustração de email" />
                    <a href='https://br.freepik.com/fotos-vetores-gratis/24' className='text-violet-400 hover:text-violet-400 font-thin text-[.5rem]'>Créditos da imagem: 24 vetor criado por vectorjuice - br.freepik.com</a>
                </div>
            </form>
        </div>
    );
  };