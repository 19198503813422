import { Container, Nav, Navbar } from 'react-bootstrap';
import iconHome from '../../../assets/icons/icon-home-nav.png'
import iconAboult from '../../../assets/icons/icon-aboult-nav.png'
import iconProject from '../../../assets/icons/icon-project-nav.png'
import iconSkill from '../../../assets/icons/icon-skill-nav.png'

export const NavBar = () => {
    return (
        <Navbar className='p-0'>
            <Container>
                <Nav className="nav-menu mx-auto gap-2">
                    <Nav.Link className='text-white text-sm font-thin inline-grid justify-items-center hover:animate-pulse hover:bg-violet-500 hover:text-white px-3 py-1 my-2 rounded-sm' href="/"><img className='w-8' src={iconHome} alt="Icone Home" /> Home</Nav.Link>
                    <Nav.Link className='text-white text-sm font-thin inline-grid justify-items-center hover:animate-pulse hover:bg-violet-500 hover:text-white px-3 py-1 my-2 rounded-sm' href="/#AboultComponent"><img className='w-8' src={iconAboult} alt="Icone Sobre" /> Sobre</Nav.Link>
                    <Nav.Link className='text-white text-sm font-thin inline-grid justify-items-center hover:animate-pulse hover:bg-violet-500 hover:text-white px-3 py-1 my-2 rounded-sm' href="/#ProjectCards"><img className='w-8' src={iconProject} alt="Icone Projetos" /> Projetos</Nav.Link>
                    <Nav.Link className='text-white text-sm font-thin inline-grid justify-items-center hover:animate-pulse hover:bg-violet-500 hover:text-white px-3 py-1 my-2 rounded-sm' href="/#ContactSkills"><img className='w-8' src={iconSkill} alt="Icone Skills" /> Skills</Nav.Link>
                </Nav>
            </Container>
        </Navbar>    
    )
}