import { BodyComponent } from "../../components/Global/BodyComponent";
import { Contact } from "../../components/Global/Contact";
import { Footer } from "../../components/Global/Footer";
import { NavBar } from "../../components/Global/NavBar";
import { VideoProjects } from "../../components/VideoProjects";

export const VideoPage = () => {
    return (
        <BodyComponent>
            <Contact />
            
            < NavBar />
            
            <VideoProjects />

            <Footer />
        </BodyComponent>
    )
}