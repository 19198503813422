import ImgStudion from '../../../assets/_imgWebProjects/_home-studion.png';
import ImgMagbank from '../../../assets/_imgWebProjects/_home_magbank.png';
import HomeTesla from '../../../assets/_imgWebProjects/_home-tesla-clone.png';
import FeedGet from '../../../assets/_imgWebProjects/_feedget-nlw.png';
import IgniteLab from '../../../assets/_imgWebProjects/_ignite-lab.png';
import BuscarCep from '../../../assets/_imgWebProjects/_busca-cep.png';

import { GoDeviceDesktop, GoDeviceMobile, GoMarkGithub } from "react-icons/go";

export function CardProject() {
    const cardsInfoProj = [
        //Meus projetos
        //{ link: "https://lucashylario.herokuapp.com/", image: ImgPort, title: "Portfólio Old", languages: "HTML5 | CSS3 | JavaScript | Bootstrap"},
    ];

    const cardsInfoCourses = [
        { 
            link: "https://studionimagine.herokuapp.com/", 
            image: ImgStudion, 
            title: "Site de eventos - StudiOn", 
            text: "StudiOn é um site de eventos e foi meu primeiro site desenvolvido no meu curso de Front end da Imagine School. Foi com este projeto que aprendi mais sobre tags HTML, estilização com CSS e um básico de JS. Também utilizamos neste projeto JQuery que é uma biblioteca de funções JavaScript, que foi muito útil para fazer as animações do site.", 
            languages: "HTML5 | CSS3 | JQuery",
            git: "https://github.com/Lucashylario/studion",
            responsive: "true",
        },
        { 
            link: "https://magbank21.vercel.app/", 
            image: ImgMagbank, 
            title: "Banco digital - Magbank", 
            text: "O Magbank é um site de banco digital que foi desenvolvido durante meu curso de Desenvolvimento web Front end na Imagine School. Esta aplicação foi desenvolvida com o objetivo de aprender mais sobre os conceitos de desenvolvimento da biblioteca ReactJS, e foi meu primeiro contato e aplicação desenvolvido com React. Também aprendemos como estilizar uma página com Bootstrap, que é uma biblioteca de estilzação que até então não conhecia.", 
            languages: "HTML5 | CSS3 | ReactJS | Bootstrap",
            git: "https://github.com/Lucashylario/magbank21",
            responsive: "true",
        },
        { 
            link: "https://tesla-page.vercel.app/", 
            image: HomeTesla, 
            title: "Home da página da TESLA", 
            text: "Com intuito de aprender uma poco mais sobre TypeScript desenvolvi este site junto à uma vídeoaula do canal da Rocketseat, onde o objetivo era reproduzi (clonar) a home do site da Tesla. Este prejeto apesar de parecer simple, foi um pouco complexo para desenvolver, já que foram útilizadas técnicas um pouco mais avançadas para o desenvolvimento das animaçõe de scroll da página.", 
            languages: "HTML5 | CSS3 | ReactJS | TypeScript | Styled Components",
            git: "https://github.com/Lucashylario/tesla-page",
            responsive: "true",
        },
        { 
            link: "https://feedget-amber.vercel.app/", 
            image: FeedGet, 
            title: "Feedback widget - Feedget", 
            text: "Feedget é uma aplicação desenvolvida durante o next level week (NLW) da Rocketseat. Esta aplicação nada mais é do que um widget para o usuário dar seu feedback, reportar erros ou sugestões para a plataforma. Embora seja uma aplicação simples, foi possível aprender mais sobre desenvolvimento com TypeScript, além de conhecer novas bibliotecas de estilização e acessibilidade, conceitos e muito mais. Também foi desenvolvido uma versão mobile e o Back end da aplicação.", 
            languages: "HTML5 | CSS3 | ReactJS | TypeScript | TailwindCSS",
            git: "https://github.com/Lucashylario/nlw-feedget-app",
            responsive: "true",
        },
        { 
            link: "https://eventplatformignitelab.vercel.app/", 
            image: IgniteLab, 
            title: "Event Platform - Ignite Lab", 
            text: "Esta aplicação React foi desenvolvida durante o evento Ignite Lab da Rocketseat, trazendo as tecnologias mais utilizadas atualmente, entre elas, GraphCMS e GraphQL. Sobre a aplicação, a Event Platform é uma plataforma de eventos online, sendo possível programar aulas em um determinado período de tempo, ou definir datas para liberação de conteúdo, assim sendo possível criar um evento como o Ignite Lab que é um evento por tempo limitado.", 
            languages: "HTML5| Vite | ReactJS | TypeScript | TailwindCSS | GraphCSM | GraphQL | Apollo | VimeJS",
            git: "https://github.com/Lucashylario/Plataforma-de-curso-online",
            responsive: "false",
        },
        { 
            link: "https://eventplatformignitelab.vercel.app/", 
            image: BuscarCep, 
            title: "Buscador de CEP", 
            text: "Projeto simples de um buscador de CEP onde você digita um CEP e ele retorna informações, como cidade, ddd entre outras. Esta aplicação foi desenvolvida com ReactJS com o objetivo de aprender um pouco mais sobre como consumir API com o Axios no React.", 
            languages: "HTML5| CSS3 | ReactJS | Axios",
            git: "https://github.com/Lucashylario/buscador-de-cep",
            responsive: "true",
        },
    ]

    const renderCard = (card, index) => {
        return (    
            <div key={index} className="mb-10 lg:w-fit px-3">
                <div className='lg:flex items-center group'>
                    <div className='z-10 justify-center flex'>
                        <a href={card.link} className="flex items-center justify-center" target="_blank" >
                            <img  
                                title='Abrir Site'
                                src={card.image} 
                                alt="Foto da home" 
                                className='transition-all rotate-0 delay-700 hover:transition-all hover:delay-200 hover:-rotate-12' 
                            />
                        </a>
                    </div>
                    <div className='card-container pt-44 px-10 mt-[-10rem] lg:pt-0 lg:w-[40rem] lg:px-0 lg:pl-44 lg:mt-0 lg:ml-[-10rem] group-hover:border-lime-500'>
                        <div className='grid lg:pr-6'>
                            <h3 className='card-title'>{card.title}</h3>
                            <p className='card-text w-auto xl:max-w-[19rem] 2xl:max-w-none'>{card.text}</p>
                            <div className='py-2 flex justify-between items-center xl:max-w-[19rem] 2xl:max-w-none'>
                                <span className='lg:w-80'>{card.languages}</span>
                                <div className='flex gap-2'>
                                    {card.git ? (<a href={card.git} title="Repositório GitHub" className='hover:text-lime-500' target="_blank"><GoMarkGithub size={25} /></a>) : "" }
                                    {card.responsive === "true" && (<a href={card.link} title="Mobile First" target="_blank" className="flex gap-1 hover:text-lime-500">< GoDeviceDesktop size={25} /> <GoDeviceMobile size={25} /></a>) || (<a href={card.link} target="_blank" title="Desktop" className="hover:text-lime-500">< GoDeviceDesktop size={25} /></a>) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            {/* <div className='projects'>
                <h2 className='text-center mb-5'>Meus projetos</h2>
                <div className="grid lg:grid-cols-2">
                    {cardsInfoProj.map(renderCard)}
                </div>
            </div> */}

            <div className='projects mt-5 mb-8'>
                {/* <h2 className='mt-5 text-center mb-5 text-3xl underline underline-offset-4 font-bold'>Desenvolvidos em cursos</h2> */}
                <div className="xl:grid xl:grid-cols-2">
                    {cardsInfoCourses.map(renderCard)}
                </div>
            </div>
        </>
    )
}
