import projectTitle from '../../../assets/project-title.png';
import iconDev from '../../../assets/icons/icon-dev-card.png';
import iconVideo from '../../../assets/icons/icon-video-card.png';
import iconDesign from '../../../assets/icons/icon-designer-card.png';
import { Link } from "react-router-dom";

export const ProjectCards = () => {

    const cardProj = [
        { link: "/projetos-Web", image: iconDev, title: "Desenvolvimento Web", numberProject: "6"},
        { link: "/projetos-videos", image: iconVideo, title: "Videomaker", numberProject: "2"},
        { link: "/design-grafico", image: iconDesign, title: "Design", numberProject: "18"},
    ]

    const renderCardProj = (info, index) => {
        return (
            <div key={index} className="project-card-link pb-5">
                <div className="
                    sm:w-2/4
                    lg:w-auto
                    mx-auto
                    project-card 
                    justify-center
                    text-white
                    text-center
                    border-l-2
                    border-violet-900     
                    hover:border-lime-500     
                ">
                    <div className="flex justify-center mt-10">
                        <img className="w-16 h-16" src={info.image} alt="Icone" />
                    </div>
                    <h2 className='text-2xl my-4'>{info.title}</h2>

                    <Link  
                    to={info.link} 
                    className='
                        inline-block 
                        w-2/4
                        py-2 
                        mb-2 
                        border-b-2 
                        border-violet-900 
                        rounded-b-lg
                        hover:text-white
                        hover:bg-violet-900
                        hover:rounded-lg
                    '>
                        Conhecer
                    </Link>

                    <hr className='w-72 bg-violet-900 mx-auto mt-4 mb-2' />
                    <span>Projetos: {info.numberProject}</span>                    
                </div>
            </div>
        )
    }

    return (
        <div className="container py-4"  id="ProjectCards">
                {/* <h2 className="porject-title text-violet-100">Projetos</h2> */}
                <img 
                    src={projectTitle} 
                    alt="Projetos título" 
                    className='mx-auto' />

                <div className="mt-5 border-2 border-violet-900 rounded-3xl">  
                    <div className='w-10/12 mx-auto project xl:grid xl:grid-cols-3'>
                        {cardProj.map(renderCardProj)}
                    </div>
                </div>
        </div>
    )
}