import { BodyComponent } from "../../components/Global/BodyComponent";
import { WebProjects } from "../../components/WebProjects";
import { Footer } from "../../components/Global/Footer";
import { Contact } from "../../components/Global/Contact";
import { NavBar } from "../../components/Global/NavBar";

export const WebProjectPage = () => {
    return (
        <BodyComponent>
            <Contact />
            < NavBar />
            
            <WebProjects />

            <Footer />
        </BodyComponent>
    )
}