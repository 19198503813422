import imgBanner from '../../../assets/img-banner.png';

import './styles.scss';

export const Header = () => {
    return (
        <div>
            <div className="row mr-0">
                <div className="header-banner mx-auto d-flex col-11 col-lg-6 justify-content-lg-right">            
                    <img className='py-5' src={imgBanner} alt="logo" />
                </div>

                <div className="header-logo bg-violet-500 col-12 col-lg-6 text-center">
                    <h2 className='pt-3 p-lg-0 text-violet-50'>LUCAS HYLARIO</h2>
                    <small className='pb-2 p-lg-0 text-violet-1000'>Desenvolvedor Web</small>
                </div>
            </div>
        </div>
    )
}