import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import { Home } from "../pages/Home";
import { WebProjectPage } from "../pages/WebProjectPage";
import { VideoPage } from "../pages/VideoPage";
import { DesignGraphicPage } from "../pages/DesignGraphicPage";

import ReactDOM from "react-dom";


export const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/projetos-Web" element={<WebProjectPage />} />
                <Route path="/projetos-videos" element={<VideoPage />} />
                <Route path="/design-grafico" element={<DesignGraphicPage />} />
            </Routes>
        </Router>
    )
}