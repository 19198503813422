import flyerBate1 from '../../assets/_photoPage/flyer_bateria_1.png';
import flyerBate2 from '../../assets/_photoPage/flyer_bateria_2.png';
import flyerBate3 from '../../assets/_photoPage/flyer_bateria_3.png';
import flyerEstetica1 from '../../assets/_photoPage/flyer_estetica_1.png';
import flyerEstetica2 from '../../assets/_photoPage/flyer_estetica_2.png';
import flyerEstetica3 from '../../assets/_photoPage/flyer_estetica_3.png';
import flyerEstetica4 from '../../assets/_photoPage/flyer_estetica_4.png';
import flyerEstetica5 from '../../assets/_photoPage/flyer_estetica_5.png';
import flyerGi1 from '../../assets/_photoPage/flyer_gi_1.png';
import flyerGi2 from '../../assets/_photoPage/flyer_gi_2.png';
import flyerGi3 from '../../assets/_photoPage/flyer_gi_3.png';
import flyerGi4 from '../../assets/_photoPage/flyer_gi_4.png';
import toga1 from '../../assets/_photoPage/camiseta_toga_0.png';
import toga2 from '../../assets/_photoPage/camiseta_toga_1.png';
import toga3 from '../../assets/_photoPage/camiseta_toga_ph.png';
import myEdition from '../../assets/_photoPage/eu_edicao.png';
import transform from '../../assets/_photoPage/_transforms.png';
import celas from '../../assets/_photoPage/celas.png';
import lucasEJailson from '../../assets/_photoPage/flyer_lucas_e_jailson.png';
import picadao from '../../assets/_photoPage/picadao_sumiga.png';


export const ImageProjects = () => {
    /* imagens */
    const folderCarouse = [
        { image: flyerGi4 },
        { image: flyerGi3 },
        { image: flyerGi2 },
        { image: flyerBate1 },
        { image: flyerBate2 },
        { image: flyerBate3 },
        { image: toga3 },
        { image: toga1 },
        { image: toga2 },
        { image: flyerEstetica1 },
        { image: flyerEstetica2 },
        { image: flyerEstetica3 },
        { image: flyerEstetica4 },
        { image: flyerEstetica5 },
        { image: flyerGi1 },
        { image: myEdition },
        { image: transform },
        { image: celas },
        { image: lucasEJailson },
        { image: picadao },
    ];

    const renderImgs = (imgs, index) => {
        return (                
            <div className=" my-2 px-1" key={index}>
                    <img className='border-zinc-500 border-2 hover:border-reds-500' src={imgs.image} alt='Foto' />
            </div>
        )
    }

    return (
        <div className='container'>
            <h2 className='text-center text-white font-bold text-6xl my-5 border-b-2 border-reds-500'>Trabalhos com Photoshop</h2>

            <div className='md:grid grid-cols-3 mb-5'>
                {folderCarouse.map(renderImgs)}
            </div>
        </div>
    )
}