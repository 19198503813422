import { Container } from 'react-bootstrap';
import './styles.scss';
import imgHtml from '../../../assets/icons/icon-html5.png';
import imgCss from '../../../assets/icons/icon-css3.png';
import imgSass from '../../../assets/icons/icon-sass.png';
import imgJs from '../../../assets/icons/icon-js.png';
import imgPhp from '../../../assets/icons/icon-php.png';
import imgSql from '../../../assets/icons/icon-sql.png';
import imgReact from '../../../assets/icons/icon-react.png';
import imgBootstrap from '../../../assets/icons/icon-bootstrap.png';
import imgGithub from '../../../assets/icons/icon-github.png';
import imgScrum from '../../../assets/icons/icon-scrum.png';
import imgXp from '../../../assets/icons/icon-xp.png';
import imgPs from '../../../assets/icons/icon-photoshop.png';
import imgVideoProd from '../../../assets/icons/icon-prod.png';
import imgVegas from '../../../assets/icons/icon-vegas.png';

export const SkillComponent = () => {
    return (
        <div className="skills" id='ContactSkills'>
            <Container>
                <h2>Skills</h2>

                <div className="skills-container row">
                    <div className="skills-card h-auto mb-3 mb-lg-5 col-8 col-lg-5 mx-auto">
                        <h3 className='my-3'>Front-end</h3>
                        <ul>
                            <li><img className='inline' src={imgHtml} alt='icone' /> HTML5  <br /> <small>Intermediário</small></li>
                            <li><img className='inline' src={imgCss} alt='icone' /> CSS3  <br /> <small>Intermediário</small></li>
                            <li><img className='inline' src={imgSass} alt='icone' /> SASS  <br /> <small>Intermediário</small></li>
                            <li><img className='inline' src={imgJs} alt='icone' /> JavaScript  <br /> <small>Básico</small></li>
                        </ul>
                    </div>
                    <div className="skills-card h-auto mb-3 mb-lg-5 col-8 col-lg-5 mx-auto">
                        <h3 className='my-3'>Back-end</h3>
                        <ul>
                            <li><img className='inline' src={imgPhp} alt='icone' /> PHP  <br /> <small>Básico</small></li>
                            <li><img className='inline' src={imgSql} alt='icone' /> SQL  <br /> <small>Básico</small></li>
                        </ul>
                    </div>
                    <div className="skills-card h-auto mb-3 mb-lg-5 col-8 col-lg-5 mx-auto">
                        <h3 className='my-3'>Framework / Bibliotecas</h3>
                        <ul>
                            <li><img className='inline' src={imgReact} alt='icone' /> ReactJS  <br /> <small>Básico</small></li>
                            <li> Tailwindcss <br /> <small>Básico</small></li>
                            <li><img className='inline' src={imgBootstrap} alt='icone' /> Bootstrap <br /> <small>Intermediário</small></li>
                        </ul>
                    </div>
                    <div className="skills-card h-auto mb-3 mb-lg-5 col-8 col-lg-5 mx-auto">
                        <h3 className='my-3'>Outros</h3>
                        <ul>
                            <li><img className='inline' src={imgGithub} alt='icone' /> GitHub  <br /> <small>Básico</small></li>
                            <li><img className='inline' src={imgScrum} alt='icone' /> Métodologia SCRUM  <br /> <small>Básico</small></li>
                            <li><img className='inline' src={imgXp} alt='icone' /> Métodologia XP  <br /> <small>Básico</small></li>
                            <li><img className='inline' src={imgPs} alt='icone' /> Photoshop  <br /> <small>Intermediário</small></li>
                            <li><img className='inline' src={imgVideoProd} alt='icone' /> Produção & Edição de vídeo</li>
                            <li><img className='inline' src={imgVegas} alt='icone' /> Vegas Pró</li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    )
}